@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'swiper/css';
@import 'swiper/css/navigation';

@font-face {
    font-family: "MillerBannerRoman";
    src: url('./fonts/MillerBannerRoman/font.woff2') format('woff2'), url('./fonts/MillerBannerRoman/font.woff') format('woff');
  }

.container{
    @apply max-w-none min-w-[344px] xs:w-[92%] lg:w-[84%] 2xl:max-w-[1338px] px-0;
}

body{
    overflow-y: overlay;
} 

h1{
    @apply font-miller-banner text-[32px] leading-[40px] font-normal lg:text-5xl lg:leading-[52px];
}

.h1{
    @apply font-miller-banner text-xl lg:text-[42px] lg:leading-[48px] font-normal;
}

h2, .h2{
    @apply font-miller-banner text-xl lg:text-[28px] lg:leading-8 font-normal;
}

h3{
    @apply font-campton-medium text-[13px] lg:text-[15px] leading-5 uppercase; 
}

p, button, a{
    @apply font-campton-book text-sm;
}

a{
    @apply text-copper;
}

.button{
    @apply font-campton-semibold border rounded-[4px] border-copper bg-copper text-white pt-[14px] pb-[10px] px-4 min-w-[105px] text-xs leading-3 uppercase tracking-[0.6px];
}

.button:disabled{
    @apply bg-gray border-gray;
}

.secondary-button{
    @apply bg-transparent text-copper;
}

.secondary-button:disabled{
    @apply bg-transparent text-gray;
}

.wider-button{
    @apply w-[182px]
}

.swiper{
    @apply ml-0;
}

.swiper .swiper-slide{
    width: fit-content;
}
/* 
.swiper-slide img {
    display: block;
    width: 196px;
    object-fit: cover;
} */

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
    @apply hidden;
}

:root{
    --swiper-navigation-size: 30px;
    --swiper-theme-color: #FFF;
    /* --swiper-navigation-size: 13px;
    --swiper-theme-color: #F8F8F8; */
    --swiper-navigation-top-offset: calc(50% - 14px);
    --swiper-navigation-sides-offset: 4px;

}
/* 
.swiper-button-prev, .swiper-button-next{
    @apply p-3.5 rounded-full bg-purple bg-opacity-[.5] flex items-center justify-center;
} */

.swiper-button-prev, .swiper-button-next{
    @apply p-3.5 flex items-center justify-center;
}

.desktop-half-container{
    @apply px-4 xs:w-[92%] lg:w-[84%] 2xl:max-w-[calc((1338px-28px)/2)]
}

.left-container{
    @apply xl:ml-auto xl:pr-0;
}

.right-container{
    @apply xl:mr-auto xl:pl-0;
}

.mobile-full-width{
    @apply first:pl-[4%] last:pr-[4%] first:lg:pl-0 last:lg:pr-0;
}

/*   
.swiper {
    width: 100%;
    height: 100%;
  } */
